import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Lottie from 'react-lottie'

import arrows from '_images/lotties/arrows.json'
import circles from '_images/lotties/circles.json'
import phone from '_images/lotties/phone.json'
import window from '_images/lotties/window.json'

import styles from './styles.module.css'

const ServiceIcons = ({ name }) => {
  const animations = {
    'uiux-design': arrows,
    'mobile-dev': phone,
    'web-dev': window,
    nextgen: circles,
  }

  const defaultOptions = useMemo(
    () => ({
      loop: true,
      autoplay: true,
      animationData: animations[name],
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    [name, animations]
  )
  return (
    <div className={styles.serviceIcons}>
      <Lottie options={defaultOptions} height="100%" width="100%" />
    </div>
  )
}

ServiceIcons.propTypes = {
  name: PropTypes.string.isRequired,
}

export default ServiceIcons
